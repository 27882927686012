<template>
    <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="800px" @close="close">
        <el-form ref="editForm" :model="form" :rules="rules" :inline="false" label-position="right" label-width="100px"
            class="editForm">
            <el-form-item label="下载进度" v-show="progressVisible">
                <el-progress :text-inside="true" :stroke-width="24" status="success" :percentage="percentage"></el-progress>
            </el-form-item>
            <el-form-item label="" v-show="progressVisible">{{ progressResult }}</el-form-item>
            <el-form-item prop="GetType" label="获取方式">
                <el-select v-model="form.GetType" placeholder="请选择" @change="typeChange">
                    <el-option v-for="(option, optionIndex) in (form.InitData || {})
                        .GetTypeList || []" :key="optionIndex" :label="option.label" :value="option.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="BeginTime" label="起止时间" v-show="typeTimeVisible">
                <el-date-picker v-model="form.BeginTime" type="datetime" placeholder="起始时间">
                </el-date-picker>
                -
                <el-date-picker v-model="form.EndTime" type="datetime" placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="Tid" label="订单号" v-show="typeTidVisible">
                <el-input v-model="form.Tid"></el-input>
            </el-form-item>
        </el-form>
        <div>
            <grid-table ref="gridTable" :tableConfig="tableConfig" :showPagination="false"
                @selectionChange="selectionChange">
            </grid-table>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button v-if="type != 'detail'" type="primary" @click="save" :disabled="progressVisible">获取</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { successCode } from "@/config";
import { submit } from "@/api/base";

export default {
    name: "DownloadOrder",
    components: {},
    data() {
        return {
            visible: false,
            progressVisible: false,
            typeTimeVisible: true,
            typeTidVisible: false,
            progressResult: "",
            percentage: 0,
            stopSync: true,
            type: "",
            title: "",
            form: {},
            rules: {},
            tableConfig: {
                columns: [
                    {
                        label: "平台",
                        prop: "PlatFormName",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "店铺名称",
                        prop: "StoreName",
                        type: "text",
                        width: "220px",
                    },
                    {
                        label: "关联码",
                        prop: "DockCode",
                        type: "text",
                        width: "180px",
                    }
                ],
            },
            selectRows: [],
            productSelectorDialogVisible: false,
        };
    },
    methods: {
        init() {
            this.type = "下载订单";
            this.title = "下载订单";
            this.$nextTick(() => {
                this.fetchData();
            });
            this.visible = true;
        },
        async fetchData() {
            const { data } = await submit("/api/base/shop/getDownloadOrderInfo", {});
            this.form = { ...data };
            this.$refs.gridTable.setData(this.form.InitData.ShopList);
        },
        selectionChange(selection) {
            this.selectRows = selection;
        },
        typeChange(selection) {
            if (selection == "0") {
                this.typeTimeVisible = true;
                this.typeTidVisible = false;
            } else {
                this.typeTimeVisible = false;
                this.typeTidVisible = true;
            }
        },
        save() {
            let selectDatas = this.selectRows;
            if (!this.selectRows || this.selectRows.length == 0) {
                this.$baseMessage("请选择要下载订单的店铺", "error");
                return;
            }
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    let param = { ...this.form };
                    param.InitData = null;
                    param.AInfoID = [];
                    selectDatas.forEach((row) => {
                        param.AInfoID.push(row["AInfoID"]);
                    });
                    this.form = param;

                    this.progressResult = "";
                    this.percentage = 0;
                    this.stopSync = false;
                    this.progressVisible = true;
                    const processID =
                        parseInt(new Date().getTime() / 1000) +
                        "" +
                        Math.ceil(Math.random() * 10);
                    this.sync(processID);
                } else {
                    return false;
                }
            });
        },
        async sync(processID) {
            const { data } = await submit("/api/newOrder/syncOrders", {
                ...this.form,
                ...{ ProcessID: processID },
            });
            if (!data.success) {
                this.$baseMessage("下载失败，" + data.msg, "error");
                this.progressVisible = false;
                this.stopSync = true;
                return;
            }
            if (data.process >= 100) {
                this.stopSync = true;
                this.$baseMessage(data.msg, "success");
                setTimeout(() => {
                    this.progressVisible = false;
                    this.$emit("handleQuery");
                    this.close();
                }, 2000);
                return;
            }
            this.progressResult = data.msg;
            this.percentage = data.process;
            if (!this.stopSync) {
                setTimeout(() => {
                    this.sync(processID);
                }, 1000);
            }
        },
        close() {
            this.progressResult = "";
            this.percentage = 0;
            this.stopSync = true;
            this.progressVisible = false;
            this.$refs.editForm.resetFields();
            this.$refs.gridTable.tableData = [];
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.editForm {
    border: 1px solid #ebeef5;
    padding-top: 10px;
    margin-bottom: 10px;
}

.operation {
    border: 1px solid #ebeef5;
    padding: 5px;
    margin-bottom: 10px;

    >div {
        display: inline-block;
        margin: 0 10px;
    }
}
</style>
